/* Global styles */
body {
  background-color: #141b33; /* Dark navy background */
  color: white; /* Gold text color */
  font-family: "Dancing Script";
  text-shadow: 1px 1px 1px #17151c;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  padding: 20px;
  display: flex;
  justify-content: flex-end; /* Align header content to the right */
  background-color: #141b33; /* Dark navy background */
  border-bottom: 1px solid white; /* Add a horizontal pinstripe */
}

.side-menu {
  background-color: #0a1444; /* Slightly darker blue background */
  width: 200px;
  padding: 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-menu li {
  padding: 10px 0;
  border-top: 1px solid white; /* White pinstripe art deco style outlines */
}

.content-container {
  flex: 1; /* Allow this to grow and take up the remaining space */
  padding: 40px 20px; /* Match padding from contact styles */
  text-align: center; /* Center text alignment */
}

.section {
  padding: 50px 0;
  border-bottom: 1px solid white; /* Add a horizontal pinstripe */
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.cake-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 10px #ffffff;
}

/* Media query for tablets */
@media screen and (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .side-menu {
    width: 100%;
    padding: 10px;
  }

  .section {
    padding: 30px 0;
  }

  .cake-image {
    max-width: 90%;
  }

  .toggle-button {
    display: block; /* Show the button on mobile devices */
  }

  .logo {
    width: 150px; /* Adjust the width of the logo for mobile */
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 480px) {
  .App-header {
    flex-direction: column;
    align-items: center;
  }

  .side-menu {
    width: 100%;
    padding: 10px;
  }

  .content-container {
    padding: 10px;
  }

  .section {
    padding: 20px 0;
  }

  .cake-image {
    max-width: 100%;
  }

  .logo {
    width: 120px; /* Adjust the width of the logo for smaller screens */
  }
}

/* Hamburger button styles */
.toggle-button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.hamburger-container {
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  right: 20px; /* Position to the right side of the screen */
}

.logo {
  width: 250px; /* Adjust the width of the logo */
  height: auto;
}

/* Gallery styles */
.gallery-container {
  padding: 40px 20px; /* Match padding from contact styles */
  text-align: center; /* Center text alignment */
}

.gallery-title {
  margin-bottom: 20px;
  font-size: 2em;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
}

.gallery-grid img,
.carousel-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px #ffffff;
  transition: transform 0.3s, z-index 0.3s;
}

.gallery-grid img:hover,
.carousel-image:hover {
  transform: scale(2);
  z-index: 10;
}

.floating-image {
  animation: float 4s ease-in-out infinite;
  transition: transform 0.2s ease, z-index 0s ease 0.2s;
}

/* Keyframes for floating effect */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Carousel styles */
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
}

.carousel-button {
  background-color: #0a1444; /* Dark blue background */
  border: none;
  color: white;
  font-size: 2em;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.carousel-button:hover {
  background-color: #141b33; /* Slightly lighter blue on hover */
}

.prev-button {
  position: absolute;
  left: 10px;
}

.next-button {
  position: absolute;
  right: 10px;
}

.carousel-image-container {
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media query for tablets */
@media screen and (max-width: 768px) {
  .carousel-container {
    flex-direction: column;
  }

  .carousel-button {
    font-size: 1.5em;
    padding: 8px;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 480px) {
  .carousel-button {
    font-size: 1.2em;
    padding: 5px;
  }

  .prev-button {
    left: 5px;
  }

  .next-button {
    right: 5px;
  }
}

/* Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0a1444;
  color: white;
  text-align: center;
  padding: 10px 0;
  transition: transform 0.5s;
}

.footer-hidden {
  transform: translateY(100%);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-icons a {
  color: white;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.footer-icons a:hover {
  color: #ffcc00; /* Gold color on hover */
}

/* Contact styles */
.contact-container {
  padding: 40px 20px;
  text-align: center;
  background-color: #141b33; /* Dark navy background */
  color: white; /* Gold text color */
  font-family: "Dancing Script";
  text-shadow: 1px 1px 1px #17151c;
}

.contact-title {
  margin-bottom: 40px;
  font-size: 2.5em;
}

.contact-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.contact-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3em;
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-icon span {
  margin-top: 10px;
  font-size: 0.5em;
  color: #ffcc00; /* Gold color */
}

.contact-icon:hover {
  color: #ffcc00; /* Gold color on hover */
}

.contact-icon svg {
  font-size: 1.5em; /* Large icon size */
}
